.topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 8vh;
    background-color: #ffffff;
    /* width: 85vw; */
    flex-grow: 1;
    transition: width 0.3s ease;
    border-bottom: 1px solid #e6e6e6;;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 20;
}

/* Left, Center, Right Sections */
.topbar-left, .topbar-center, .topbar-right {
    display: flex;
    align-items: center;
}

/* Hamburger Icon */
.topbar-menu-icon {
    font-size: 24px;
    margin-right: 20px;
    cursor: pointer;
    color: var(--primary-color);
}

/* Logo (optional) */
.topbar-logo {
    height: 30px;
    margin-right: 10px;
}

/* Company Name */
.topbar-company-name {
    font-size: 18px;
    font-weight: 500;
    color: var(--primary-color);
}

/* Search Box */
.topbar-search {
    margin-left: 100px;
    position: relative;
    width: 500px;
}

.topbar-search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #696969;
}

.topbar-search-input {
    width: 100%;
    padding: 8px 8px 8px 40px;
    border: 1px solid #b2b2b2;
    border-radius: 20px;
    font-size: 14px;
    background-color: #ddd;
}

/* Right Icons & Layout */
.topbar-right {
    display: flex;
    align-items: center;
}

/* Generic Icon Style */
.topbar-icon {
    font-size: 20px;
    margin-right: 20px;
    color: #333;
    cursor: pointer;
}

/* Wrapper for the bell icon and dropdown */
.topbar-icon-wrapper {
    position: relative;
    margin-right: 20px;
    cursor: pointer;
}

/* Notifications Dropdown */
.notifications-dropdown {
    position: absolute;
    top: 35px;
    right: 0;
    width: 250px;
    padding: 8px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;  /* rounding corners */
    box-shadow: 0 2px 5px rgba(0,0,0,0.15);
    z-index: 999;

    /* Start hidden/offscreen by default */
    opacity: 0;
    transform: translateY(-10px);
    pointer-events: none;
    transition: all 0.3s ease;
}

/* When .open is applied, show dropdown */
.notifications-dropdown.open {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

/* Dropdown Content */
.notifications-dropdown h4 {
    margin: 0;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
}

.notifications-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.notifications-dropdown li {
    border-bottom: 1px solid #eee;
    padding: 6px 0;
    font-size: 14px;
    color: #333;
}

.notifications-dropdown li:last-child {
    border-bottom: none;
}

.view-more {
    margin-top: 8px;
    text-align: right;
}

.view-more a {
    color: var(--primary-color);
    text-decoration: none;
    font-size: 14px;
}

.view-more a:hover {
    text-decoration: underline;
}

/* Topbar User Section (Profile) */
.topbar-user {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}

.topbar-user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #0066FF;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.topbar-user-name {
    margin-left: 10px;
    font-weight: 500;
}

/* Settings Icon */
.topbar-settings-icon {
    font-size: 24px;
    cursor: pointer;
    margin-left: 10px;
}
