/* src/pages/admin.css */

.admin-page {
    min-height: calc(92vh);
    /* background: linear-gradient(to bottom, #f0f4f8, #e5e9f0); */
    padding: 2rem;
    width: 100%;
    animation: gradientShift 10s ease infinite;
}

@keyframes gradientShift {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.admin-content {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.admin-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.admin-title-section {
    flex: 1;
}

.admin-actions {
    display: flex;
    gap: 1rem;
}

.admin-title {
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
    transition: color 0.3s ease;
}

.admin-title:hover {
    color: var(--primary-color);
}

.admin-org-info h2 {
    font-size: 1.2rem;
    color: #2c3e50;
    margin-bottom: 0.25rem;
    transition: transform 0.3s ease;
}

.admin-org-info:hover h2 {
    transform: translateX(5px);
}

.admin-metrics {
    display: flex;
    gap: 1rem;
}

.metric-card {
    background-color: #ffffff;
    border-radius: 25px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 150px;
    transition: all 0.3s ease;
    border: 1px solid #e6e6e6;
}

.metric-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.metric-card svg {
    color: var(--primary-color);
    margin-bottom: 0.5rem;
    transition: transform 0.3s ease;
}

.metric-card:hover svg {
    transform: scale(1.1);
}

.metric-value {
    font-size: 1.5rem;
    font-weight: 700;
    color: #2c3e50;
    transition: color 0.3s ease;
}

.metric-card:hover .metric-value {
    color: var(--primary-color);
}

.admin-section {
    background-color: #ffffff;
    border-radius: 25px;
    padding: 1rem 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    border: 1px solid #e6e6e6;
}

.admin-section:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.section-title {
    font-size: 1rem;
    color: #2c3e50;
    transition: color 0.3s ease;
}

.section-title:hover {
    color: var(--primary-color);
}

.action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--primary-color);
    color: #ffffff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 30px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.action-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.table-header {
    display: grid;
    grid-template-columns: 2fr 3fr 2fr 1fr;
    padding: 0.5rem;
    background-color: #f8f9fa;
    border-radius: 30px;
    font-weight: 600;
    color: #2c3e50;
    transition: background-color 0.3s ease;
}

.table-header:hover {
    background-color: #e9ecef;
}

.table-body {
    max-height: 33%;
    overflow-y: auto;
    overflow-x: hidden;
}

.table-row {
    display: grid;
    grid-template-columns: 2fr 3fr 2fr 1fr;
    padding: 0.5rem;
    background-color: #ffffff;
    border-radius: 30px;
    margin-bottom: 0.5rem;
    transition: all 0.3s ease;
}

.table-row:hover {
    background-color: #f8f9fa;
    transform: translateX(5px);
}

.status-badge {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.8rem;
    font-weight: 600;
    transition: all 0.3s ease;
}

.status-badge.active {
    background-color: #d4edda;
    color: #155724;
}

.status-badge.active:hover {
    background-color: #c3e6cb;
    transform: scale(1.05);
}

.charts-section-container {
    display: flex;
}

/* Loading Spinner Container */
.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .admin-header {
        flex-direction: column;
    }

    .admin-metrics {
        margin-top: 1.5rem;
        width: 100%;
        justify-content: space-between;
    }

    .metric-card {
        flex: 1;
    }
}

@media (max-width: 768px) {
    .admin-page {
        padding: 1rem;
    }

    .admin-metrics {
        flex-direction: column;
    }

    .section-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .admin-actions {
        margin-top: 1rem;
        width: 100%;
    }

    .action-button {
        flex: 1;
        justify-content: center;
    }

    .table-header,
    .table-row {
        grid-template-columns: 1fr 1fr;
    }

    .table-header div:nth-child(3),
    .table-header div:nth-child(4),
    .table-row div:nth-child(3),
    .table-row div:nth-child(4) {
        display: none;
    }
}