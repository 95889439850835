/* TypingIndicator.css */
.typingIndicator {
    display: flex;
    align-items: center;
  }
  
  .typingIndicator span {
    background-color: #888;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 0 3px;
    animation: pulse 1s infinite ease-in-out;
    animation-delay: 0s;
  }
  
  .typingIndicator span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .typingIndicator span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes laoding-pulse {
    0%, 100% { transform: scale(0.75); opacity: 0.5; }
    50% { transform: scale(1); opacity: 1; }
  }