.profile-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
}

.profile-modal {
    background: #fff;
    padding: 20px;
    width: 400px;
    border-radius: 8px;  /* rounding corners */
    box-shadow: 0 2px 8px rgba(0,0,0,0.3);
    animation: fadeInScale 0.3s ease forwards;
}

.profile-modal h2 {
    margin-top: 0;
    margin-bottom: 16px;
}

.profile-modal-content label {
    display: block;
    margin-bottom: 4px;
    font-weight: 500;
}

.profile-modal-content input,
.profile-modal-content textarea {
    width: 100%;
    margin-bottom: 12px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.profile-modal-actions {
    text-align: right;
}

.profile-modal-actions button {
    padding: 8px 16px;
    cursor: pointer;
}

/* Simple fade/scale animation */
@keyframes fadeInScale {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
