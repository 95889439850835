.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-family: 'Inter', sans-serif; /* Make sure to include this font in your project or swap with a similar one */
}

.manage-groups-modal {
  width: 90%;
  height: 90%;
  max-width: 1200px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0 8px 24px rgba(0,0,0,0.1);
  overflow: hidden;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
}

.user-groups-modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.modal-close-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #444;
  transition: color 0.2s ease;
}

.modal-close-button:hover {
  color: #000;
}

.user-groups-list {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 10px; /* Some spacing to avoid scrollbar overlap */
}

.user-group {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin-bottom: 10px;
  background-color: #fafafa;
  transition: background-color 0.2s ease;
}

.user-group:hover {
  background-color: #f3f3f3;
}

.group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 6px 6px 0 0;
}

.group-header:hover {
  background-color: #f0f0f0;
}

.user-group-name {
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
}

.group-users {
  padding: 15px;
  border-top: 1px solid #e0e0e0;
  background-color: #ffffff;
  border-radius: 0 0 6px 6px;
}

.group-users p {
  margin: 5px 0;
  color: #555;
  font-size: 0.95rem;
  line-height: 1.4;
}

.group-users h4 {
  margin: 10px 0 8px;
  font-weight: 500;
  color: #333;
}

.group-user {
  padding: 5px 0;
  font-size: 0.95rem;
  color: #333;
}

.add-user-to-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  border-top: 1px solid #e7e7e7;
  padding-top: 20px;
}

.modal-select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #fff;
  font-size: 0.95rem;
  flex: 1;
  min-width: 200px;
  color: #333;
  transition: border-color 0.2s ease;
}

.modal-select:focus {
  border-color: #007bff;
  outline: none;
}

.modal-submit-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
  transition: background-color 0.2s ease;
  white-space: nowrap;
}

.modal-submit-button:hover {
  background-color: #0056b3;
}

.add-group-form {
  display: flex;
  gap: 10px;
  padding-top: 20px;
  border-top: 1px solid #e7e7e7;
  align-items: center;
}

.modal-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 0.95rem;
  transition: border-color 0.2s ease;
}

.modal-input:focus {
  border-color: #007bff;
  outline: none;
}

@media (max-width: 768px) {
  .manage-groups-modal {
      width: 95%;
      height: 95%;
      padding: 15px;
  }

  .user-groups-modal-title {
      font-size: 1.25rem;
  }

  .modal-submit-button {
      font-size: 0.85rem;
  }

  .modal-input, .modal-select {
      font-size: 0.9rem;
  }
}
