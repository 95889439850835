/* sidebar.css */
:root {
  --text-color: #333;
  --bg-color: #ffffff;
  --hover-color: #e2e8f0;
  --selected-color: #3b82f6;
  --transition-speed: 0.3s;
  --sidebar-width: 15vw;
  --sidebar-collapsed-width: 60px;
}

.sidebar {
  min-width: var(--sidebar-collapsed-width);
  background-color: var(--bg-color);
  /* border-right: 1px solid #d0d0d0; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  flex-shrink: 0;
  height: 100%;
  max-height: 100%;
  box-shadow: 2px 0 4px rgba(0,0,0,0.1);
}

.sidebar.collapsed {
  width: var(--sidebar-collapsed-width);
}

.argus-logo-placeholder {
  font-size: 36px;
  font-weight: 700;
  color: var(--primary-color);
  text-align: center;
  padding: 20px 0;
  transition: font-size var(--transition-speed) ease;
  white-space: nowrap;
  width: 100%;
}

.sidebar.collapsed .argus-logo-placeholder {
  font-size: 36px;
}

.sidebar-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 200px;
}

.sidebar-menu-item {
  width: 100%;
  height: 45px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.sidebar-link {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  color: var(--text-color);
  text-decoration: none;
  transition: background-color var(--transition-speed) ease;
  border-radius: 8px;
  margin: 0 10px;
  width: calc(100% - 20px);
}

.sidebar.collapsed .sidebar-link {
  justify-content: center;
  padding: 12px 0;
}

.sidebar-icon {
  font-size: 1.2rem;
  min-width: 24px;
}

.sidebar-link:hover {
  background-color: var(--hover-color);
}

.sidebar-link span {
  margin-left: 35px;
  font-size: 14px;
  font-weight: 500;
  transition: opacity var(--transition-speed) ease;
  white-space: nowrap;
}

.sidebar.collapsed .sidebar-link span {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  margin-left: 0;
}

.sidebar-menu-item.selected .sidebar-link {
  background-color: var(--primary-color);
  color: white;
}

.sidebar-logout {
  padding: 0px 0;
  text-align: center;
  width: 100%;
}

.logout-button {
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 12px 20px;
  width: calc(100% - 20px);
  margin: 0 10px;
  transition: background-color var(--transition-speed) ease;
  border-radius: 8px;
}

.sidebar-feedback{
  padding: 10px 0;
  text-align: center;
  
  width: 100%;
}

.feedback-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 12px 20px;
  width: calc(100% - 20px);
  margin: 0 10px;
  transition: background-color var(--transition-speed) ease;
  border-radius: 8px;
  color: var(--primary-color);
  text-wrap: nowrap;
}

.sidebar-footer{
  padding: 0px 0;
  text-align: center;
  width: 100%;
}

.sidebar.collapsed .logout-button {
  width: 100%;
  margin: 0;
  padding: 12px 0;
}

.logout-button:hover {
  background-color: var(--hover-color);
}

.logout-button .sidebar-icon {
  color: #e53e3e;
}

.sidebar.collapsed .logout-button .sidebar-icon {
  margin-right: 0;
}

.sidebar.collapsed .logout-button span {
  display: none;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .sidebar-menu {
    flex-direction: row;
    justify-content: space-around;
  }

  .sidebar-menu-item {
    width: auto;
  }

  .sidebar-link {
    flex-direction: column;
    padding: 10px;
  }

  .sidebar-link span {
    margin-left: 0;
    margin-top: 5px;
    font-size: 12px;
  }

  .sidebar-logout {
    display: none;
  }
}