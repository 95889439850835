@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

:root {
    --primary-color: #007bff; /* Adjust if you have a different primary color */
    --background-color: #fff;
    --text-color: #333;
    --border-color: #ddd;
    --secondary-text-color: #666;
    --hover-bg-color: #f9f9f9;
    --light-gray: #f0f0f0;
}

body {
    font-family: 'Inter', sans-serif;
    color: var(--text-color);
    margin: 0;
    padding: 0;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    background-color: var(--background-color);
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    width: 90%;
    max-height: 85vh;
    overflow-y: auto;
    box-shadow: 0 8px 24px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    animation: fadeInUp 0.3s ease-out;
}

@keyframes fadeInUp {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Add smooth transitions to most interactive elements
*, *::before, *::after {
    transition: all 0.2s ease;
} */

/* Close Button */
.modal-close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    color: var(--secondary-text-color);
    font-size: 1.5rem;
    cursor: pointer;
}

.modal-close-btn:hover {
    color: var(--text-color);
    transform: translateY(-2px);
}

/* Header */
.chat-history-header {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    color: var(--text-color);
}

/* History Actions (Shared With Me & Search) */
.history-actions {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.history-actions button {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
}

.history-actions button:hover {
    background-color: #005dc1;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.history-search-bar {
    flex: 1;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    padding: 8px;
    font-size: 14px;
    outline: none;
    color: var(--text-color);
}

.history-search-bar::placeholder {
    color: #999;
}

/* History List */
.history-list {
    flex: 1;
    overflow-y: auto;
    padding-right: 5px;
}

.history-list::-webkit-scrollbar {
    width: 8px;
}

.history-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}
.history-list::-webkit-scrollbar-thumb:hover {
    background-color: #8e8e8e;
    border-radius: 10px;
}

.history-list::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.history-list h4 {
    margin: 20px 0 10px 0;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 5px;
}

.history-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.no-shared-chats {
    text-align: center;
    padding: 20px;
    color: var(--secondary-text-color);
    font-size: 14px;
}

/* History Item */
.history-item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--border-color);
    padding: 15px 0;
    position: relative;
}

/* Apply subtle motion on hover */
.history-item:hover {
    background-color: var(--hover-bg-color);
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

/* Session Content */
.session-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

/* Session Info and Actions */
.session-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.session-id {
    font-weight: 500;
    font-size: 15px;
    color: var(--text-color);
}

.timestamp, .shared-by, .expires-at {
    font-size: 13px;
    color: var(--secondary-text-color);
}

/* Session Actions (Icons) */
.session-actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.session-actions svg {
    font-size: 20px;
    color: var(--secondary-text-color);
    cursor: pointer;
}

/* Hover lift icons slightly and change their color */
.session-actions svg:hover {
    color: var(--text-color);
    transform: translateY(-2px) scale(1.1);
}

/* Rename Input */
.rename-input {
    width: 70%;
    padding: 8px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 14px;
    outline: none;
    margin-right: 10px;
}

.submit-change-name {
    padding: 8px 12px;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.submit-change-name:hover {
    background-color: #005dc1;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

/* Load More Button */
.load-more-button {
    width: 100%;
    padding: 10px;
    background-color: var(--light-gray);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 14px;
    color: var(--text-color);
    font-weight: 500;
}

.load-more-button:hover {
    background-color: #e0e0e0;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

/* Delete Modal */
.delete-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--background-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0,0,0,0.15);
    width: 300px;
    max-width: 90%;
    z-index: 1100;
    text-align: center;
    animation: fadeInUp 0.3s ease-out;
}

.delete-modal h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    color: var(--text-color);
}

.delete-modal p {
    color: var(--secondary-text-color);
    margin-bottom: 20px;
    font-size: 14px;
}

.delete-modal-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.delete-confirm,
.delete-cancel {
    flex: 1;
    padding: 8px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.delete-confirm {
    background-color: #dc3545;
    color: #fff;
}

.delete-confirm:hover {
    background-color: #b92b3a;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.delete-cancel {
    background-color: #f1f1f1;
    color: var(--text-color);
}

.delete-cancel:hover {
    background-color: #e0e0e0;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

/* Responsive Design */
@media (max-width: 480px) {
    .modal-content {
        padding: 15px;
    }

    .chat-history-header {
        font-size: 18px;
    }

    .rename-input {
        width: 60%;
    }
}
