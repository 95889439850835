/*
 * loadingSpinner.css
 *
 * This file contains the styles for a loading spinner.
 * It defines the visual appearance and animation of the spinner.
 */

 /*
  * The .loading-spinner class
  *
  * This class defines the styles for a loading spinner element.
  * It uses inline-block to create a block element with inline behavior,
  * sets the size, border, border radius, and the top border color,
  * and applies the spin animation to the spinner.
  */
  .loading-spinner {
    display: inline-block; /* Allows sizing and layout */
    width: 50px; /* Sets the width of the spinner */
    height: 50px; /* Sets the height of the spinner */
    border: 3px solid rgba(0, 0, 0, 0.1); /* Creates the circular border */
    border-radius: 50%; /* Makes the border a circle */
    border-top-color: var(--primary-color); /* Sets the color of the top border, creating the appearance of spinning */
    animation: spin 1s ease-in-out infinite; /* Applies the spin animation, 1 second ease in out infinitely */
  }
  
/*
 * The @keyframes spin
 *
 * This keyframes animation defines the spin animation, which rotates the
 * element 360 degrees continuously.
 */
  @keyframes spin {
    to { transform: rotate(360deg); } /* Rotate 360 degrees to create a full spin */
  }
