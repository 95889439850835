/* src/components/ConfirmationModal.css */

.confirmation-modal-overlay {
    position: fixed; /* Positions the overlay relative to the viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    z-index: 1000; /* Ensures the modal is on top of other elements */
  }
  
  .confirmation-modal {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .confirmation-modal p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #333;
  }
  
  .confirmation-modal-buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .confirm-button,
  .cancel-button {
    padding: 8px 16px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .confirm-button {
    background-color: #dc3545; /* Red for confirmation */
    color: #fff;
  }
  
  .confirm-button:hover {
    background-color: #c82333;
  }
  
  .cancel-button {
    background-color: #6c757d; /* Grey for cancellation */
    color: #fff;
  }
  
  .cancel-button:hover {
    background-color: #5a6268;
  }
  