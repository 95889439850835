.filter-bar {
    width: 100%;
    margin-bottom: 1vh;
    background-color: var(--primary-color);
    border-radius: 15px;
    color: white;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.filters-logo {
    margin-right: 10px;
    font-size: 20px;
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 3vh;
    padding: 0 15px;
}

.filter-label {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.expanded-content {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.5rem 0.5rem 0.5rem;
    height: auto;
    max-height: calc(40vh - 4vh);
    background-color: var(--primary-color);
    color: black;
    transition: all 0.3s ease;
}

.filters-grid {
    margin-top: 28px;
    width: 33.33%;
    /* 1/3 of the total width */
}

.filter-button {
    padding: 0.5rem;
    background-color: white;
    border: 1px solid #ccc;
    height: 4vh;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 10px;
    text-align: left;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--primary-color);
    font-weight: 600;
}

.filter-count {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 0.8em;
}

.similar-divider {
    margin: 10px 0;
    padding: 5px;
    background-color: #f0f0f0;
    text-align: center;
    font-weight: bold;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.filter-option-label mark {
    background-color: yellow;
    padding: 0;
}

.instructions-column {
    width: 66.67%;
    /* 1/3 of the total width */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
}

.custom-instructions-header {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 0;
    color: white;
}

.instructions-column textarea {
    width: 100%;
    height: 15vh;
    padding: 1rem;
    border-radius: 15px;
    border: 1px solid #ccc;
    resize: none;
}

.custom-instructions-buttons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.apply-button,
.clear-button {
    padding: 0.25rem 1rem;
    border-radius: 20px;
    background-color: white;
    border: none;
    width: 30%;
    color: var(--primary-color);
    font-weight: 600;
    cursor: pointer;
}

/* Full-screen popup styles */
.filter-popup-overlay {
    position: fixed;
    transform: translateX(-16vw);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    /* Increased z-index to ensure it's above all elements */
}

.filter-popup {
    background-color: white;
    border-radius: 10px;
    width: 90%;
    height: 90%;
    max-width: 1200px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filter-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: var(--primary-color);
    color: white;
}

.filter-popup-header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.filter-popup-header button {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: white;
}

.filter-popup-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
}

.filter-popup-footer {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    background-color: #f0f0f0;
    border-top: 1px solid #ddd;
}

.filter-popup-button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.filter-popup-button.apply {
    background-color: var(--primary-color);
    color: white;
}

.filter-popup-button.clear {
    background-color: #f44336;
    color: white;
}

.filter-popup-button:hover {
    opacity: 0.9;
}

.filter-option-label {
    display: block;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.filter-option-label:last-child {
    border-bottom: none;
}

.filter-option-label:hover {
    background-color: #f0f0f0;
}

.filter-option-label input[type="checkbox"] {
    margin-right: 10px;
}