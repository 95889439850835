/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #007bff;
  --background-color-light: #ffffff;
  --background-color-dark: #111111;
  --text-color-light: #000000;
  --text-color-dark: #ffffff;
  --accent-color: #0056b3;
  --gradient-dark: linear-gradient(135deg, #1e1e1e 0%, #333 100%);
  --gradient-light: linear-gradient(135deg, #ffffff 0%, #a8a8a8 100%);
  --gradient-light-menu: linear-gradient(180deg, #ffffff 0%, #e8e8e8 130%);
  --font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --transition-speed: 0.3s;
}

body {
  /* background-color: rgb(234, 234, 234); */
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
.top-bar-container{
  display: flex;
  transition: width 0.3s ease;
  
}

.corner-logo-block{
  width: 15vw;
  height: 8vh;
  background-color: #ffffff;
  z-index: 30;
  transition: width 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.corner-logo-block.collapsed{
  width: 5vw;
  transition: width 0.3s ease-in-out;
}


body.dark-mode {
  background-color: var(--background-color-dark);
}


/* App.css */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow: hidden; /* Prevents content from overflowing */  
}

.top-bar-and-page-content{
  display: flex;
  align-items: center;
  width: 100vw;
  height: 92vh;
  transition: 0.3s ease;
}

.sidebar {
  min-width: 60px; /* Minimum width when collapsed */
  background-color: #ffffff; /* Adjust as needed */
  border-right: 1px solid #e6e6e6;
}


.top-bar-and-page-content.collapsed {
  width: 100vw; /* Full width when sidebar is collapsed */
  transition: width 0.3s ease;
}


/* .top-bar-and-page-content > * {
  flex-shrink: 0;
  transition: width 0.3s ease;
} */

.file-explorer {
  flex: 1; /* Occupies remaining space within top-bar-and-page-content */
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevents FileExplorer from affecting layout */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100; /* Ensure it appears above other elements */
}



.loading-spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #f3f3f3;
  border-top: 6px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px; /* Space between spinner and text */
}

.argus-logo{
  width: 3vw;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
