.notification-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .notification {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    color: white;
    opacity: 0.9;
    transition: opacity 0.3s ease;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

  }
  
  .notification:hover {
    opacity: 1;
  }
  
  .notification.success {
    background-color: #4CAF50;
  }
  
  .notification.error {
    background-color: #f44336;
  }
  
  .notification.info {
    background-color: #2196F3;
  }

  .close-notification-button {
    background: none;
    border: none;
    font-size: 21px;
    cursor: pointer;
    color: #ffffff;
  }