.settings-container {
    max-width: 600px;
    width: 40%;
    margin: 40px auto;
    background: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    font-family: Arial, sans-serif;
  }
  
  .settings-container h2 {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 24px;
  }
  
  .settings-section {
    margin-bottom: 24px;
  }
  
  .settings-section h3 {
    margin: 0 0 10px;
    font-size: 18px;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }
  
  .form-group label {
    font-weight: 500;
    margin-bottom: 4px;
  }
  
  .form-group input[type="text"],
  .form-group input[type="email"] {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .toggle-group {
    margin-bottom: 8px;
  }
  
  .toggle-group label {
    display: flex;
    align-items: center;
    font-weight: 500;
  }
  
  .toggle-group input[type="checkbox"] {
    margin-right: 8px;
  }
  
  .theme-options {
    display: flex;
    gap: 16px;
    margin-top: 8px;
  }
  
  .theme-options label {
    display: flex;
    align-items: center;
    font-weight: 500;
  }
  
  .theme-options input[type="radio"] {
    margin-right: 4px;
  }
  
  .save-button {
    display: inline-block;
    background: #007BFF;
    color: #fff;
    padding: 10px 16px;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    cursor: pointer;
  }
  
  .save-button:hover {
    background: #0056b3;
  }
  