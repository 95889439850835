.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Overlay behind modal */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .modal {
    background: #fff;
    width: 400px;
    max-width: 90%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
    animation: fadeIn 0.2s ease-out;
    display: flex;
    flex-direction: column;
  }
  
  .modal h2 {
    margin: 0;
    padding: 16px;
    background: #f5f5f5;
    font-size: 18px;
    font-weight: normal;
    border-bottom: 1px solid #ddd;
  }
  
  .folder-list {
    padding: 16px;
    max-height: 300px;
    overflow-y: auto;
    font-size: 14px;
    font-family: sans-serif;
    position: relative;
  }
  
  /* Each folder item */
  .folder-item {
    position: relative;
    padding: 4px 0;
  }
  
  /* Indentation handled by inline styles (marginLeft) or data-level attributes.
     If you use data-level, you could do something like:
     .folder-item[data-level="1"] { margin-left: 20px; }
     .folder-item[data-level="2"] { margin-left: 40px; }
     etc.
  */
  
  /* Lines: vertical/horizontal connectors */
  .folder-item::before {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    border-left: 1px solid #ccc;
    top: 0;
  }
  
  /* A horizontal connector line that connects the radio button line to the vertical line */
  .folder-item::after {
    content: '';
    position: absolute;
    left: -20px; /* adjust if needed based on marginLeft */
    top: 10px;
    width: 20px;
    border-top: 1px solid #ccc;
  }
  
  /* For top-level items (parentId=null), you might remove the vertical line */
  .folder-item.root::before,
  .folder-item.root::after {
    content: none;
  }
  
  /* Label styling */
  .folder-list label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
  }
  
  .folder-list input[type="radio"] {
    margin-right: 8px;
  }
  
  /* Scrollbar styling */
  .folder-list::-webkit-scrollbar {
    width: 8px;
  }
  
  .folder-list::-webkit-scrollbar-track {
    background: #eee;
  }
  
  .folder-list::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }
  
  /* Modal buttons */
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    border-top: 1px solid #ddd;
  }
  
  .modal-buttons button {
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    margin-left: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.2s ease;
  }
  
  .modal-buttons button:disabled {
    background: #999;
    cursor: not-allowed;
  }
  
  .modal-buttons button:hover:not(:disabled) {
    background: #0056b3;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  