@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

:root {
  --primary-color: #007bff; /* Adjust if your primary color is different */
  --background-color-light: #fff;
  --text-color: #333;
  --light-border-color: #e0e0e0;
  --hover-bg-color: #f5f5f5;
  --secondary-text-color: #888;
}

body {
  font-family: 'Inter', sans-serif;
}

/* The main container for the chat history panel */
.chat-history-panel {
  width: 32vw;
  height: 94%;
  background-color: var(--background-color-light);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  border-radius: 12px;
  margin-left: 1%;
  margin-right: 1%;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Ensure cleaner edges */
  color: var(--text-color);
}

.chat-history-panel.open {
  transform: translateX(0);
}

/* Header Section */
.chat-history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid var(--light-border-color);
}

.chat-history-header-title {
  font-size: 20px;
  font-weight: 500;
  color: var(--text-color);
  margin: 0;
}

.chat-history-shared-with-me-button {
  background-color: var(--primary-color);
  color: #fff;
  padding: 6px 12px;
  border: none;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.chat-history-shared-with-me-button:hover {
  background-color: #005dc1;
}

/* Search Bar */
.chat-history-search-bar {
  width: 90%;
  padding: 8px;
  border-radius: 8px;
  margin: 10px auto;
  border: 1px solid var(--light-border-color);
  font-size: 14px;
  color: var(--text-color);
  outline: none;
}

.chat-history-search-bar::placeholder {
  color: #999;
}

/* Content Section */
.history-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  text-align: left;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}

.history-content h4 {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  margin: 20px 0 10px 0;
  border-bottom: 1px solid var(--light-border-color);
  padding-bottom: 5px;
}

/* Session Lists */
.history-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.history-content li {
  background-color: transparent;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease, opacity 0.2s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  opacity: 0;
  transform: translateY(15px);
}

.chat-history-panel.open .history-content li {
  opacity: 1;
  transform: translateY(0);
}

.history-content li:hover {
  background-color: var(--hover-bg-color);
}

/* Session Content */
.session-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/* Session ID line with icons */
.session-id {
  font-weight: 500;
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

/* Timestamp and shared-by info */
.timestamp,
.shared-by,
.expires-at {
  font-size: 13px;
  color: var(--secondary-text-color);
}

.rename-icon,
.chat-delete-icon,
.chat-share-icon {
  font-size: 28px;
  width: 28px;
  height: 28px;
  opacity: 0;
  transition: opacity 0.2s ease;
  cursor: pointer;
  color: var(--secondary-text-color);
}


.session-id:hover .rename-icon,
.session-id:hover .chat-delete-icon,
.session-id:hover .chat-share-icon {
  opacity: 1;
}

/* Slight spacing between icons */
.rename-icon {
  margin-left: auto;
}

.chat-share-icon {
  margin-left: 8px;
}

.chat-delete-icon {
  margin-left: 8px;
}

/* Icon hover states */
.rename-icon:hover {
  color: var(--primary-color);
}

.chat-delete-icon:hover {
  color: #ff4444;
}

.chat-share-icon:hover {
  color: #00aa66;
}

/* Rename Input */
.rename-input {
  width: 80%;
  padding: 6px;
  border-radius: 8px;
  border: 1px solid var(--light-border-color);
  font-size: 14px;
  outline: none;
  margin-bottom: 5px;
}

/* Rename Save Button */
.submit-change-name {
  background-color: var(--primary-color);
  color: white;
  padding: 6px 12px;
  border: none;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.submit-change-name:hover {
  background-color: #005dc1;
}

/* Loading Spinner Container */
.chat-history-panel .loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100px;
  background-color: rgba(255, 255, 255, 0.8);
}

/* Load More Button */
.load-more-button {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  margin: 20px auto;
  display: block;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: #005dc1;
}

/* No Shared Chats Text */
.no-shared-chats {
  text-align: center;
  color: var(--secondary-text-color);
  font-size: 14px;
  margin: 30px 0;
}

/* Delete Modal */
.delete-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--primary-color);
  width: 90%;
  max-width: 400px;
  padding: 20px;
  border-radius: 20px;
  color: #fff;
  z-index: 1000;
  box-shadow: 0 0px 50px rgba(0, 0, 0, 0.6);
  text-align: center;
}

.delete-modal h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.delete-modal p {
  margin-bottom: 20px;
}

.delete-modal button {
  background-color: #fff;
  color: var(--primary-color);
  width: 100px;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

.delete-modal button:hover {
  background-color: #e0e0e0;
}

.delete-modal button:first-child {
  background-color: red;
  color: #fff;
}

.delete-modal button:first-child:hover {
  background-color: #c00;
}

/* Responsive Adjustments */
@media (max-width: 767px) {
  .chat-history-panel {
    position: fixed;
    top: 10%;
    width: 80%;
    height: 80%;
    right: -1%;
    transform: translateX(100%);
  }
  
  .chat-history-panel.open {
    transform: translateX(0);
  }
}
