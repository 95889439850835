@import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/violet.css";
@import "@radix-ui/colors/slate.css";

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
  margin-top: 7px;
  margin-bottom: 7px;
}

/* Trigger Styles */
.my-select-trigger {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  height: 35px;
  width: 250px;
  gap: 5px;
  background-color: white;
  color: var(--slate-11);
  border: 1px solid var(--slate-6);
  /* box-shadow: 0 2px 5px var(--black-a7); */
}

.my-select-trigger:hover {
  background-color: var(----slate-3);
}

.my-select-trigger:focus {
  box-shadow: 0 0 0 2px black;
}

.my-select-trigger[data-placeholder] {
  color: var(--slate-12);
}

/* Content Styles */
.my-select-content {
  overflow-y: scroll;
  background-color: white;
  width: 250px;
  max-height: 100px;
  border-radius: 6px;
  margin-top: 2px;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.5),
    0px 10px 20px -15px rgba(22, 23, 24, 0.35);
}

/* Item Styles */
.my-select-item {
  font-size: 13px;
  line-height: 1;
  color: var(--slate-12);
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
}

.my-select-item:hover {
  background-color: #f0f0f0;
}

.my-select-item[data-state="checked"] {
  background-color: #e0e0e0;
  font-weight: bold;
}

/* (Optional) Hide the scrollbar, style it if needed */
.my-select-content::-webkit-scrollbar {
  width: 6px;
}

.my-select-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}

