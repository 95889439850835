/* src/customAuthenticatorStyles.css */

.amplify-authenticator {
    max-width: 400px;
    margin: 0 auto;
  }
  
  .amplify-form-section {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 30px;
  }
  
  .amplify-button--primary {
    background-color: #0068d7;
    color: #ffffff;
  }
  
  .amplify-button--primary:hover {
    background-color: #0056b3;
  }
  
  .amplify-label {
    color: #333333;
    font-weight: 600;
  }
  
  .amplify-input {
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 1rem;
  }
  
  .amplify-input:focus {
    border-color: #0068d7;
    outline: none;
  }
  
  /* Add more custom styles as needed */
  