.job-description-upload-main-area {
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    width: 100%;
    /* let the parent decide the final width */
    background-color: #fff;
    color: var(--text-color);
    transition: background-color 0.3s ease;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
    /* a softer shadow */
    padding: 2rem;
    /* some spacing inside the card */
    box-sizing: border-box;
    margin: 0 auto;
    /* center horizontally if needed */
    height: 100%;
    border: 1px solid #e6e6e6;
}

.recruit-ai-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.recruit-ai-header {
    display: flex;
    font-size: 32px;
    margin: 0;
}

.job-description-upload-header {
    font-size: 20px;
    margin-bottom: 1rem;
    font-weight: 500;
}

/* Toggle Switch Container */
.toggle-switch {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
}

/* Form */
.job-description-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
    /* consistent spacing */
    width: 100%;
}

/* Inputs */
.job-description-job-title-input,
.job-description-description-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e6e6e6;
    font-size: 14px;
    border-radius: 25px;
}

/* Larger text area for description */
.job-description-description-input {
    max-height: 500px;
    min-height: 250px;
}

/* File input and label */
.custom-file-upload {
    display: inline-block;
    padding: 0.75rem 1.25rem;
    cursor: pointer;
    background-color: var(--primary-color, #007bff);
    color: #fff;
    border-radius: 6px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    transition: background-color 0.3s ease;
}

.custom-file-upload:hover {
    background-color: #0056b3;
}

.file-name {
    margin-top: 0.5rem;
    font-size: 14px;
}

/* Buttons */
.job-description-buttons-container {
    display: flex;
    gap: 1rem;
}

.job-description-upload-button,
.job-description-reset-button {
    padding: 0.5rem 1rem;
    border: none;
    font-weight: 400;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
}

.job-description-upload-button {
    background-color: var(--primary-color, #007bff);
    color: #fff;
}

.job-description-upload-button:hover {
    background-color: #0056b3;
}

.job-description-reset-button {
    background-color: #dc3545;
    color: #fff;
}

.job-description-reset-button:hover {
    background-color: #b02a37;
}

/* Results Section */
.job-description-results {
    width: 100%;
    margin-top: 1rem;
    max-height: 700px;
    /* scroll area if you have many results */
    overflow-y: auto;
    padding-right: 1rem;
    /* some space for scrollbar */
}

.job-description-results::-webkit-scrollbar {
    width: 10px;
}

.job-description-results::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.job-description-results::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
    margin-top: 7px;
    margin-bottom: 7px;
}

.result-card {
    background-color: #f8f9fa;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 8px;
}

/* Score sections */
.resume-scores {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.secondary-score {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.small-score-number {
    font-size: 20px;
    font-weight: 600;
    margin-top: 0.25rem;
}

.reasoning-header {
    font-size: 18px;
    font-weight: 600;
    margin: 1rem 0 0.5rem 0;
}

/* Reset button at bottom after results */
.reset-button {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.reset-button:hover {
    background-color: #0056b3;
}

/* Loading Overlay covers the form so user can't click behind it */
.loading-overlay {
    position: absolute;
    /* or fixed if you want it to cover entire screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* semi-transparent black overlay */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    /* ensure it's above other elements */
}

/* Simple spinner using a rotating border approach */
.loading-spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-text {
    color: #fff;
    font-size: 18px;
}