.SemanticSearch {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    /* Ensures the container matches the viewport height */
}

/* This is your main container for doc + chunk sections */
.search-content {
    display: flex;
    flex-direction: column;
    width: 80%;
    min-width: 80%;
    /* Add this: */
    position: relative;
}

.similar-docs-section {
    width: 100%;
    box-sizing: border-box;
}

/* The chunks section grows to fill leftover space. */
.similar-chunks-section {
    display: flex;
    flex-direction: column;
    /* Ensure it can shrink/scroll within the parent */
    min-height: 0;
}

.search-input {
    width: 100%;
    height: 5vh;
    padding: 8px;
    padding-left: 25px;
    border-top-left-radius: 2.5vh;
    border-bottom-left-radius: 2.5vh;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #e6e6e6;
}

.big-search-text {
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight: 500;
}

.mid-search-placeholder-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

}

.small-search-text {
    font-size: 1.25rem;
    color: rgb(134, 134, 134);
    font-weight: 300;
    text-align: center;
}

.search-bar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    margin-top: 2rem;
    width: 100%;
}

.semantic-search-bar {
    width: 60%;
    display: flex;
    align-items: center;
}

.semantic-search-button {
    background-color: #2979ff;
    color: white;
    width: 5vh;
    height: 5vh;
    transform: translateX(-2.5vh);
    padding: 0.5rem 1rem;
    border-radius: 100%;
    border: 1px solid #e6e6e6;
    cursor: pointer;
}

.pulsing-dots {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 20vh);
    display: flex;
    justify-content: space-between;
    width: 72px;
    /* Adjust based on the number of dots and their spacing */
}

.pulsing-dots div {
    width: 16px;
    height: 16px;
    background: #2979ff;
    border-radius: 50%;
    animation: pulsing 1.4s infinite ease-in-out both;
}

.pulsing-dots div:nth-child(1) {
    animation-delay: -0.32s;
}

.pulsing-dots div:nth-child(2) {
    animation-delay: -0.16s;
}

@keyframes pulsing {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}

/* Accordions */
.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 0;
}

/* Document Rows */
.docs-list {
    margin-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.some-placeholder-box {
    padding: 3rem;
}

.search-placeholder {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30vh;
    border-radius: 3vh;
    width: 100%;
    border: 1px solid #e6e6e6;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: visible;
}

.search-document-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    padding: 8px;
    height: 5vh;
    margin-bottom: 1vh;
    border-radius: 3vh;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    min-width: 100%;
}

.doc-info {
    display: flex;
    align-items: center;
}

.doc-icon {
    margin-right: 8px;
    margin-left: 1vw;
}

.doc-actions button {
    margin: 0 4px;
    background-color: transparent;
    color: var(--primary-color);
}

.search-section-header {
    font-size: 1.5rem;
}

/* Chunk Rows */
/* Let the actual list scroll */
.chunks-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 60vh;
    overflow-y: scroll;
    margin-bottom: 1rem;
}

.chunks-list::-webkit-scrollbar {
    width: 10px;
}

.chunks-list::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.chunks-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
    margin-top: 7px;
    margin-bottom: 7px;
}

.chunk-row {
    padding: 8px;
    background-color: #ffffff;
    margin-bottom: 2vh;
    border-radius: 3vh;
    height: 20vh;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 1rem;
    border: 1px solid #e6e6e6;
}

.chunk-details {
    padding: 2vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chunk-content {
    width: 80%;
    height: 10vh;
    overflow: hidden;
}

.chunk-content p {
    text-overflow: ellipsis;
    /* display an ellipsis for clipped text */
}

.chunk-source {
    font-size: x-large;
    margin-bottom: 0;
}

.chunk-meta {
    font-size: small;
}

.chunk-header {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
}

.chunk-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 8vh;
}

.chunk-action-button {
    border: 2px solid #4d4d4d;
    border-radius: 25px;
    width: 10vw;

}

/* Fullscreen viewer overlay */
.fullscreen-viewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.viewer-header {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
}

.close-button {
    background: #fff;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
}