:root {
  --primary-color: #3b82f6;
  --primary-hover: #2563eb;
  --background-color: #f9fafb;
  --card-background: #ffffff;
  --text-primary: #1f2937;
  --text-secondary: #4b5563;
  --border-color: #e5e7eb;
  --success-color: #059669;
  --danger-color: #dc2626;
  --warning-color: #ffd900;
  --font-family: 'Inter', sans-serif; /* Use a clean, modern font */
}

body {
  font-family: var(--font-family);
}

.document-page-container {
  width: 98%;
  height: 89%;
  margin-top: 1%;
  padding: 2rem;
  background-color: var(--card-background);
  border-radius: 15px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.back-button {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: var(--text-secondary);
  background: transparent;
  border: 1px solid var(--border-color);
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 1rem;
}

.back-button:hover {
  background-color: var(--background-color);
  color: var(--text-primary);
  border-color: var(--primary-color);
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 2rem 0 1rem 0;
}

.document-info-container {
  background-color: var(--card-background);
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
}

.details-table th {
  background-color: var(--background-color);
  font-weight: 600;
  color: var(--text-primary);
  text-align: left;
  padding: 1rem;
  border-bottom: 2px solid var(--border-color);
  font-size: 0.9rem;
}

.details-table td {
  padding: 1rem;
  color: var(--text-secondary);
  border-bottom: 1px solid var(--border-color);
  font-size: 0.9rem;
}

.details-table tbody tr:hover {
  background-color: #f2f4f7;
}

.chunk-data-container {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1.5rem;
}

.document-info-label {
  color: var(--text-primary);
  font-weight: 600;
  margin: 0;
  font-size: 0.95rem;
}

.document-info-bidai-label {
  background-color: green;
  font-weight: 600;
  margin: 0;
  font-size: 0.95rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
}

/* Button to enable BidAI */
.enable-bidai-btn {
  background-color: green;
  color: white;
  /* Add any other styling you want */
}

/* Button to disable BidAI */
.disable-bidai-btn {
  background-color: red;
  color: white;
  /* Add any other styling you want */
}

.button-link {
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: #ffffff;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
  text-decoration: none;
}

.button-link:hover {
  background-color: var(--primary-hover);
}

.versions-container {
  max-height: 400px;
  overflow-y: auto;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: var(--card-background);
}

.versions-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.versions-table thead {
  background-color: var(--background-color);
  position: sticky;
  top: 0;
  z-index: 1;
}

.versions-table th {
  padding: 1rem;
  text-align: left;
  font-weight: 600;
  color: var(--text-primary);
  border-bottom: 2px solid var(--border-color);
  font-size: 0.9rem;
}

.versions-table td {
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.versions-table tbody tr:last-child td {
  border-bottom: none;
}

.status-complete {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background-color: var(--success-color);
  color: white;
  border-radius: 1rem;
  font-size: 0.875rem;
}

.status-incomplete {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background-color: var(--warning-color);
  color: black;
  border-radius: 1rem;
  font-size: 0.875rem;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.document-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 32px;
  border-radius: 6px;
  border: none;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
  padding: 0;
}

.document-icon-button:hover {
  background-color: var(--primary-hover);
}

.document-icon-button.revert:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.document-page-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  color: var(--text-secondary);
}

.document-page-error {
  padding: 2rem;
  text-align: center;
  color: var(--danger-color);
  background-color: #fee2e2;
  border-radius: 0.5rem;
  margin: 2rem 0;
  font-size: 1rem;
}

.version-row {
  transition: background-color 0.2s ease;
}

.version-row:hover {
  background-color: #f2f4f7;
}

.current-version-row {
  background-color: #eef2ff !important;
  font-weight: 500;
}

.current-version-row td {
  color: var(--text-primary) !important;
}

.version-cell {
  color: var(--primary-color);
  cursor: pointer;
  transition: color 0.2s ease;
}

.version-cell:hover {
  color: var(--primary-hover);
  text-decoration: underline;
}

.version-cell.current-version {
  color: var(--text-primary);
  text-decoration: none !important;
  cursor: default;
}

@media (max-width: 768px) {
  .document-page-container {
    margin: 1rem;
    padding: 1rem;
  }

  .details-table th,
  .details-table td,
  .versions-table th,
  .versions-table td {
    padding: 0.75rem;
    font-size: 0.85rem;
  }

  .back-button {
    font-size: 0.8rem;
  }

  .button-link {
    font-size: 0.85rem;
  }
}
