/* feedbackModal.css */
.feedback-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .feedback-modal {
    background: #fff;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.3);
    position: relative;
  }
  
  .feedback-modal h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  
  .feedback-modal label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  .feedback-modal input,
  .feedback-modal textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .feedback-modal textarea {
    height: 100px;
    resize: vertical;
  }
  
  .feedback-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
  
  .feedback-buttons button {
    padding: 0.5rem 1rem;
    cursor: pointer;
    border: none;
    border-radius: 4px;
  }
  
  .feedback-buttons button:first-of-type {
    background-color: #007bff;
    color: #fff;
  }
  
  .feedback-buttons button:first-of-type:hover {
    background-color: #0056b3;
  }
  
  .feedback-buttons button:last-of-type {
    background-color: #aaa;
    color: #fff;
  }
  
  .feedback-buttons button:last-of-type:hover {
    background-color: #888;
  }
  