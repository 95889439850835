.google-drive-modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
    padding: 1rem;
}

.google-drive-modal {
    background-color: white;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 800px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
}

.modal-header h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
}

.close-button {
    padding: 0.25rem;
    border-radius: 9999px;
    border: none;
    background: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.close-button:hover {
    background-color: #f3f4f6;
}

.modal-navigation {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.path-display {
    font-size: 0.875rem;
    color: #6b7280;
}

.back-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    background-color: #eff6ff;
    color: #2563eb;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.2s;
    white-space: nowrap;
}

.back-button:hover {
    background-color: #dbeafe;
}

.google-modal-content {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    min-height: 0;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.loading-spinner {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 2px solid #e5e7eb;
    border-top-color: #2563eb;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.error-message {
    color: #dc2626;
    text-align: center;
}

.empty-message {
    color: #6b7280;
    text-align: center;
}

.items-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    width: 100%;
    min-width: 0;
}

.item {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s;
    min-width: 0;
}

.item.folder:hover {
    background-color: #f9fafb;
}

.item.file:hover {
    background-color: #eff6ff;
}

.item.selected {
    background-color: #eff6ff;
    border: 1px solid #bfdbfe;
}

.icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.75rem;
    flex-shrink: 0;
}

.folder-icon {
    color: #eab308;
}

.file-icon {
    color: #6b7280;
}

.check-icon {
    color: #2563eb;
}

.item-name {
    flex: 1;
    margin: 0 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.modal-footer {
    padding: 1rem;
    border-top: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selected-count {
    font-size: 0.875rem;
    color: #6b7280;
}

.upload-button {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    border: none;
    background-color: #2563eb;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
}

.upload-button:hover:not(.disabled) {
    background-color: #1d4ed8;
}

.upload-button.disabled {
    background-color: #e5e7eb;
    color: #9ca3af;
    cursor: not-allowed;
}

/* Add these styles to your existing GoogleDriveModal.css */

.modal-navigation {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e5e7eb;
    background-color: #f9fafb;
}

.breadcrumbs-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.25rem;
    min-height: 32px;
    padding: 0.25rem 0;
}

.breadcrumb-item {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    color: #374151;
    background: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.breadcrumb-item:hover {
    background-color: #e5e7eb;
}

.breadcrumb-item.home {
    color: #2563eb;
    font-weight: 500;
}

.breadcrumb-item.home svg {
    margin-right: 0.25rem;
}

.breadcrumb-separator {
    color: #9ca3af;
    flex-shrink: 0;
}

/* Make the breadcrumb items responsive */
@media (max-width: 640px) {
    .breadcrumb-item {
        max-width: 120px;
    }
}

/* Update existing modal styles for better consistency */
.google-drive-modal {
    background-color: white;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 800px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
}

.modal-header {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-content {
    flex: 1;
    overflow-y: auto;
    min-height: 0;
}

/* Enhanced folder/file items */
.items-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.25rem;
    padding: 0.5rem;
}

.item {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.item:hover {
    background-color: #f3f4f6;
}

.item.selected {
    background-color: #eff6ff;
    border: 1px solid #bfdbfe;
}

.item-name {
    margin-left: 0.75rem;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}