.resume-upload-main-area {
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    width: 100%;
    background-color: #fff;
    color: var(--text-color);
    transition: background-color 0.3s ease;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #e6e6e6;
    padding: 2rem;
    box-sizing: border-box;
    position: relative; /* ensures overlay can be absolutely positioned within */
    height: 100%;

  }

  .resume-upload-header{
    display: flex;
    align-items: center;
    font-size: 20px;
    margin: 0;
    margin-bottom: 1rem;
    justify-content: space-between;
  }
  
  /* Title */
  .resume-upload-main-area h2 {
    font-weight: 500;
    margin: 0 0 1rem 0;
  }
  
  /* Drag-Drop Area */
  .drag-drop-area {
    width: 100%;
    height: 200px;
    border: 2px dashed #007bff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #007bff;
    cursor: pointer;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
  }
  
  .drag-drop-area:hover {
    background-color: #eef6ff;
  }
  
  /* File List */
  .file-list {
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .file-card {
    background-color: #f1f1f1;
    border-radius: 6px;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    color: var(--text-color);
    font-size: 14px;
  }
  
  /* Buttons */
  .button-container {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    margin-left: 2rem;
  }
  
  .button-container button {
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
    color: #fff;
    background-color: #007bff;
  }
  
  .button-container button:hover {
    background-color: #0056b3;
  }
  
  button.clear {
    background-color: #dc3545;
  }
  
  button.clear:hover {
    background-color: #b02a37;
  }

  