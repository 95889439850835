.dialog-object::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    
}

.dialog-object::-webkit-scrollbar-thumb {
    background: #888888;
    border-radius: 5px;
}

.dialog-object::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    
}