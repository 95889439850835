.candidates-main-area {
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    width: 100%;
    max-height: 54%;
    height: 54%;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    color: var(--text-color);
    transition: background-color 0.3s ease;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    box-sizing: border-box;
  }
  
  .candidates-main-area h2 {
    font-weight: 500;
    margin: 0 0 1rem 0;
  }
  
  /* Candidate List */
  .candidate-list {
    list-style-type: none;
    padding: 1rem;
    margin: 0;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .candidate-list::-webkit-scrollbar {
    width: 10px;
}

.candidate-list::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
}

.candidate-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
    margin-top: 7px;
    margin-bottom: 7px;
}

  
  /* Candidate Item */
  .candidate-item {
    display: flex;
    align-items: center;
    background-color: #007bff;
    color: #fff;
    border-radius: 8px;
    height: 5vh;
    padding: 1rem;
    margin-bottom: 0.5rem;
    transition: transform 0.2s ease;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  
  .candidate-item:hover {
    transform: scale(1.02);
  }
  
  .candidate-icon {
    font-size: 24px;
    margin-right: 15px;
  }
  
  .candidate-info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .candidate-name{
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
  }
  
  
  .candidate-sub-info {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    justify-content: space-between;
    margin-top: 0
  }
  
  .candidate-sub-info p {
    margin: 0;
    font-size: 14px;
  }
  