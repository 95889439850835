/* FileExplorer.css */

/* Root Variables for Consistency and Easy Customization */
:root {
  /* --primary-color: #4a90e2; Primary accent color */
  --secondary-color: #f9f9f9; /* Light grey for backgrounds */
  --border-color: #e0e0e0; /* Subtle border color */
  --text-color: #333333; /* Default text color */
  --hover-background: rgba(74, 144, 226, 0.1); /* Light primary color on hover */
  --transition-speed: 0.3s; /* Transition duration */
  --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern sans-serif font */
}

/* General Styles */
.file-explorer {
  height: 95%;
  width: 95%;
  margin: 1vw;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2); /* Softer shadow for a flatter look */
  transition: background-color width var(--transition-speed) ease;
  font-family: var(--font-family);
  border: 1px solid #e6e6e6;
}

/* File Main Area */
.file-main-area {
  overflow-x: auto; /* Enable horizontal scroll */
  overflow-y: auto; /* Enable vertical scroll if needed */
  padding: 1rem;
  padding-right: 2rem; /* Add extra padding for scrollbar */
  flex: 1 1 auto;
  display: block;
  position: relative;
}

.file-main-area::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.file-main-area::-webkit-scrollbar-thumb {
  background: #888888;
  border-radius: 5px;
}

.file-main-area::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.file-main-area.dragging {
  border: 2px dashed #4a90e2;
  background-color: rgba(74, 144, 226, 0.1);
}

.drag-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(74, 144, 226, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  font-size: 1.5rem;
  color: #4a90e2;
}

/* File Explorer Header */
.file-explorer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.docx-viewer-window{
  height: 100%;
  width: 100%;
}

/* Header Text */
.header-text {
  font-size: 36px;
  font-weight: 600;
  color: var(--text-color);
}

/* Search and Add Button */
.file-search-bar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 700px;
}

.file-search-bar input {
  width: 100%;
  padding: 10px 15px 10px 40px; /* Padding left for the search icon */
  border: 1px solid #d0d0d0;
  border-radius: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.10);
  font-size: 14px;
  transition: border-color var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
}

.file-search-bar input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 2px 12px rgba(74, 144, 226, 0.3);
  outline: none;
}

.file-search-bar .search-icon {
  position: absolute;
  left: 15px;
  color: #888888;
  font-size: 18px;
}

/* Add Folder Button */
.file-explorer-add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  width: 7vw;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
  font-size: 14px;
  font-weight: 500;
  outline: none; /* Remove the default focus outline */
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5); /* Starting state for custom focus effect */
}

.file-explorer-add-button:hover {
  background-color: #0673e0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.file-explorer-add-button .button-folder-icon {
  margin-right: 8px;
  font-size: 18px;
}

/* Breadcrumb Navigation */
.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
  font-size: 14px;
  color: var(--primary-color);
}

.breadcrumbs span {
  cursor: pointer;
  transition: color var(--transition-speed) ease;
}

.breadcrumbs span:hover {
  color: #357abd;
}

/* Back Button */
.top-row-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.back-button {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
  font-size: 14px;
  font-weight: 500;
}

.back-button:hover {
  background-color: #0673e0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.back-button .AiOutlineArrowLeft {
  margin-right: 8px;
  font-size: 18px;
}

/* Bulk Actions */
.bulk-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.bulk-actions button {
  padding: 8px 20px;
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
  font-size: 14px;
  font-weight: 500;
}

.bulk-actions button:hover {
  background-color: #c9302c;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Table Styles */
.file-explorer-table {
  width: 100%;
  min-width: 900px; /* Ensure table is wider than container for horizontal scroll */
  margin-top: 20px;
  font-size: 14px;
  table-layout: auto; /* Changed from fixed to auto for independent resizing */
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #d0d0d0;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); /* Softer shadow for a flatter look */
}

.file-explorer-table thead {
  background-color: var(--secondary-color);
}

.file-explorer-table th,
.file-explorer-table td {
  padding: 10px 15px;
  text-align: left;
  white-space: nowrap; /* Prevent wrapping for better column widths */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  transition: background-color var(--transition-speed) ease, color var(--transition-speed) ease;
}



.file-explorer-table th {
  position: relative;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  color: var(--text-color);
}

.file-explorer-table th .sort-icon {
  margin-left: 5px;
  font-size: 12px;
  vertical-align: middle;
  color: #888888;
}

/* Checkbox Column */
.file-explorer-table th.checkbox-column,
.file-explorer-table td.checkbox-column {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  text-align: center;
}

.type-icon-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Type Column (Fixed, Non-Resizable) */
.type-column {
  width: 70px; /* Fixed width */
  min-width: 70px;
  max-width: 70px;
  text-align: center;
}

.file-explorer-table tbody tr.folder-row {
  background-color: #fafafa;
  cursor: pointer;
  transition: background-color var(--transition-speed) ease;
}

.file-explorer-table tbody tr.folder-row:hover {
  background-color: var(--hover-background);
}

.file-explorer-table tbody tr.document-row {
  transition: background-color var(--transition-speed) ease, transform var(--transition-speed) ease;
}

.file-explorer-table tbody tr.document-row:hover {
  background-color: var(--hover-background);
  transform: translateY(-2px); /* Slight lift on hover */
}

.file-explorer-table tbody tr td input[type="checkbox"] {
  cursor: pointer;
}

/* Type Icon Centering Without Flexbox */
.file-explorer-table tbody tr td.type-cell {
  padding: 0; /* Remove padding to prevent height issues */
}

.file-explorer-table tbody tr td.type-cell .type-icon {
  font-size: 26px; /* Increased from 18px */
  color: var(--primary-color);
}

/* Name Column Enhancements */
.file-explorer-table tbody tr td.name-cell {
  font-size: 16px; /* Increased from 14px */
  font-weight: 500;
  color: var(--text-color);
}

/* Added By Column */
.file-explorer-table tbody tr td.addedBy-cell {
  font-size: 14px;
  color: var(--text-color);
}

/* Action Buttons */
.file-explorer-table tbody tr td .action-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.file-explorer-table tbody tr td .action-buttons button.view-button,
.file-explorer-table tbody tr td .action-buttons button.share-button {
  padding: 6px 14px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px; /* Increased font size */
  font-weight: 500;
  transition: background-color var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
}

.file-explorer-table tbody tr td .action-buttons button.view-button:hover,
.file-explorer-table tbody tr td .action-buttons button.share-button:hover {
  background-color: #0673e0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.file-explorer-table tbody tr td .action-buttons .delete-icon,
.file-explorer-table tbody tr td .action-buttons .edit-icon,
.file-explorer-table tbody tr td .action-buttons .save-icon {
  color: #888888;
  cursor: pointer;
  transition: color var(--transition-speed) ease;
}

.file-explorer-table tbody tr td .action-buttons .delete-icon:hover {
  color: #e74c3c;
}

.file-explorer-table tbody tr td .action-buttons .edit-icon:hover {
  color: var(--primary-color);
}

.file-explorer-table tbody tr td .action-buttons .save-icon:hover {
  color: #2ecc71;
}

/* Resizable Header Cells */
.resizable-th {
  position: relative;
  background-color: var(--secondary-color);
}

.resizable-th .th-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resizable-th .resizer {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  cursor: col-resize;
  background-color: transparent;
}

.resizable-th .resizer::after {
  content: "";
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 4px;
  border-right: 2px solid #888888;
  border-bottom: 2px solid #888888;
}

.actions-cell{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.document-view-icon{
  font-size: 20px;
  color: #007bff;
  cursor: pointer;
}

.document-share-icon{
  font-size: 20px;
  color: #007bff;
  cursor: pointer;
}

.document-delete-icon{
  font-size: 20px;
  color: #007bff;
  cursor: pointer;
}

.document-project-edit-icon{
  font-size: 20px;
  color: #007bff;
  cursor: pointer;
}

.project-cell{
  margin: 0px;
}


/* Table Responsiveness */
@media (max-width: 768px) {
  .file-explorer-table th,
  .file-explorer-table td {
    padding: 10px 12px;
    font-size: 13px;
  }

  .file-explorer-table tbody tr td.name-cell {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .file-explorer {
    padding: 10px;
  }

  .header-text {
    font-size: 24px;
  }

  .file-explorer-add-button,
  .back-button,
  .bulk-actions button {
    padding: 6px 10px;
    font-size: 12px;
  }

  .file-search-bar {
    max-width: 100%;
    margin: 0;
  }

  .file-explorer-add-button {
    width: 100%;
    justify-content: center;
  }

  .file-explorer-table thead {
    display: none; /* Hide table headers on small screens */
  }

  .file-explorer-table tbody tr {
    display: block;
    margin-bottom: 15px;
    border: none;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 8px;
  }

  .file-explorer-table tbody tr td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
  }

  .file-explorer-table tbody tr td:last-child {
    border-bottom: none;
  }

  .file-explorer-table tbody tr td::before {
    content: attr(data-label);
    font-weight: bold;
    width: 100px;
    display: inline-block;
    color: #555555;
  }

  .file-explorer-table tbody tr td .action-buttons {
    gap: 5px;
  }

  .bulk-actions {
    flex-direction: column;
    align-items: flex-start;
  }

  .bulk-actions button {
    width: 100%;
  }
}

/* Fullscreen Viewer */
.fullscreen-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.viewer-header {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 28px;
  cursor: pointer;
  transition: opacity var(--transition-speed) ease;
}

.close-button:hover {
  opacity: 0.7;
}

.viewer-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewer-iframe {
  width: 90%;
  height: 90%;
  border: none;
}

.viewer-error {
  color: white;
  font-size: 18px;
}

/* Modals */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal,
.confirmation-modal,
.share-popup {
  background-color: #ffffff;
  padding: 20px 30px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  font-family: var(--font-family);
}

.modal h2,
.confirmation-modal h2,
.share-popup h2 {
  margin-top: 0;
  font-size: 20px;
  color: var(--text-color);
}

.modal input,
.confirmation-modal input,
.share-popup input {
  width: 100%;
  padding: 8px 12px;
  margin: 10px 0;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  font-size: 14px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
}

.create-button,
.share-button {
  background-color: var(--primary-color);
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color var(--transition-speed) ease;
  font-size: 14px;
  font-weight: 500;
}

.create-button:hover,
.share-button:hover {
  background-color: #0673e0;
}

.cancel-button {
  background-color: #aaa;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color var(--transition-speed) ease;
  font-size: 14px;
  font-weight: 500;
}

.cancel-button:hover {
  background-color: #888888;
}

/* Confirmation Modal */
.confirmation-modal p {
  margin: 20px 0;
}

/* Dark Mode */
body.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

body.dark-mode .file-explorer,
body.dark-mode .file-explorer-header,
body.dark-mode .file-explorer-table,
body.dark-mode .file-explorer-table th,
body.dark-mode .file-explorer-table td,
body.dark-mode .modal,
body.dark-mode .confirmation-modal,
body.dark-mode .share-popup,
body.dark-mode .file-search-bar input,
body.dark-mode .file-explorer-add-button,
body.dark-mode .back-button,
body.dark-mode .bulk-actions button {
  background-color: #1e1e1e;
  border-color: #333333;
  color: #e0e0e0;
}

body.dark-mode .header-text,
body.dark-mode .file-explorer-table th,
body.dark-mode .file-explorer-table td,
body.dark-mode .file-search-bar input,
body.dark-mode .breadcrumbs span,
body.dark-mode .bulk-actions button {
  color: #e0e0e0;
}

body.dark-mode .file-explorer-table thead {
  background-color: #2c2c2c;
}

body.dark-mode .file-explorer-table tbody tr.folder-row {
  background-color: #2c2c2c;
}

body.dark-mode .file-explorer-table tbody tr.folder-row:hover,
body.dark-mode .file-explorer-table tbody tr.document-row:hover {
  background-color: #3a3a3a;
}

body.dark-mode .file-explorer-table th,
body.dark-mode .file-explorer-table td {
  border-color: #444444;
}

body.dark-mode .file-explorer-table tbody tr td .action-buttons .delete-icon,
body.dark-mode .file-explorer-table tbody tr td .action-buttons .edit-icon,
body.dark-mode .file-explorer-table tbody tr td .action-buttons .save-icon {
  color: #cccccc;
}

body.dark-mode .file-explorer-table tbody tr td .action-buttons .delete-icon:hover {
  color: #ff6b6b;
}

body.dark-mode .file-explorer-table tbody tr td .action-buttons .edit-icon:hover {
  color: var(--primary-color);
}

body.dark-mode .file-explorer-table tbody tr td .action-buttons .save-icon:hover {
  color: #2ecc71;
}

body.dark-mode .file-explorer-add-button:hover,
body.dark-mode .back-button:hover,
body.dark-mode .bulk-actions button:hover {
  background-color: #333333;
}

body.dark-mode .modal,
body.dark-mode .confirmation-modal,
body.dark-mode .share-popup {
  background-color: #2c2c2c;
  border-color: #555555;
}

body.dark-mode .modal button.create-button,
body.dark-mode .share-popup button.share-button {
  background-color: var(--primary-color);
}

body.dark-mode .modal button.cancel-button,
body.dark-mode .confirmation-modal button.cancel-button,
body.dark-mode .share-popup button.cancel-button {
  background-color: #555;
}

.add-button-container {
  position: relative;
  display: inline-block;
}

.file-explorer-add-button {
  display: flex;
  align-items: center;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 2;
  position: relative;
}

.file-explorer-add-button span {
  margin-left: 5px;
}

.add-dropdown {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  background-color: #007bff;
  border-radius: 0 0 20px 20px;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: all 0.3s ease, opacity 0.2s ease;
  z-index: 1;
  font-size: 12px;
}
/* src/components/fileExplorer/fileExplorer.css */

.document-name-button {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
}

.document-name-button:hover {
  color: #0056b3;
}


.add-dropdown.show {
  top: calc(100% - 20px);  /* Overlap slightly with the button */
  max-height: 350px; /* Adjust based on content */
  opacity: 1;
}

.dropdown-content {
  padding-top: 20px; /* Match the button's bottom padding */
}

.add-dropdown button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  border: none;
  background: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.add-dropdown button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.add-dropdown button svg {
  margin-right: 10px;
}

/* Full-Screen Upload Overlay */
.upload-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5); /* Semi-transparent gray */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it sits above all other elements */
}

.upload-overlay span {
  margin-top: 20px;
  font-size: 18px;
  color: #fff;
}

/* Optional: Style for Upload Error Message */
.upload-error {
  /* position: fixed; Position fixed to overlay on top */
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffdddd;
  color: #d8000c;
  padding: 20px;
  border: 1px solid #d8000c;
  border-radius: 5px;
  z-index: 1001; /* Ensure it sits above the overlay */
}

/* Optional: Add Animation to the Overlay (Fade In) */
.upload-overlay {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* src/components/fileExplorer/FileExplorer.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other elements */
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.create-button,
.cancel-button {
  padding: 8px 16px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.create-button {
  background-color: #28a745;
  color: white;
}

.create-button:hover {
  background-color: #218838;
}

.cancel-button {
  background-color: #dc3545;
  color: white;
}

.cancel-button:hover {
  background-color: #c82333;
}


/* FileExplorer.css */

/* Ensure the table takes full width and has border collapse */
.file-explorer-table {
  width: 100%;
  table-layout: fixed; /* Allows columns to respect the set widths */
  border: 1px solid #d0d0d0;
}


/* Table header cells */
.file-explorer-table th {
  position: relative; /* To position the resizer absolutely within the th */
  padding: 10px;
  text-align: left;
  border: 1px solid #d0d0d04e; /* Lighter border color */
  border-bottom: 2px solid #d0d0d0;
  background-color: #f9f9f9;
  user-select: none; /* Prevent text selection during drag */
}

/* Table data cells */
.file-explorer-table td {
  padding: 10px;
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  white-space: nowrap; /* Prevent text from wrapping */
}


/* Prevent pointer events on the resizer's adjacent content */
.th-content {
  padding-right: 10px; /* Space for the resizer */
}

/* Style for the selected row */
.document-row:hover,
.folder-row:hover {
  background-color: #f1f1f1;
}

/* Additional styles as needed */
.type-icon {
  font-size: 1.2em;
  color: #555;
}

.type-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Sort icons */
.th-content {
  display: flex;
  align-items: center;
}

.th-content svg {
  margin-left: 5px;
  font-size: 0.8em;
}

/* Resizing cursor */
.resizer:hover {
  background-color: #aaa;
}

/* Other styles for table, icons, buttons, etc. */
/* 1. Tags Cell Container */
.tags-cell {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Allows tags to wrap to the next line if necessary */
  gap: 8px; /* Space between tags and the add button */
  overflow: hidden;
}

/* 2. Hide 'No Tags' When Editing */
.tags-cell.editing .no-tags {
  display: none;
}

/* 3. Add Tag Button */
.add-tag-button {
  background-color: #007bff; /* Bootstrap primary blue */
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin-left: 8px;
  transition: background-color 0.3s ease;
}

.add-tag-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.add-tag-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Focus ring for accessibility */
}

/* Ensure the icon has no extra margins and is centered */
.add-tag-button .icon {
  font-size: 16px; /* Adjust as needed */
  margin: 0;
  line-height: 1;
}

/* 4. 'No Tags' Text Styling */
.no-tags {
  font-size: 14px;
  color: #888888;
}

/* 5. Tag Badge */
.tag-badge {
  display: inline-flex;
  align-items: center;
  background-color: #e0e0e0; /* Light gray background */
  color: #333; /* Dark text */
  border-radius: 12px;
  padding: 4px 8px;
  margin: 2px;
  font-size: 14px;
}

.tag-badge .tag-delete-icon {
  margin-left: 4px;
  cursor: pointer;
  color: #ff4d4f; /* Red color for delete icon */
  transition: color 0.3s ease;
}

.tag-badge .tag-delete-icon:hover {
  color: #d9363e; /* Darker red on hover */
}

.tag-badge .tag-delete-icon:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.5); /* Focus ring for accessibility */
}

/* 6. Tag Input Container */
.tag-input-container {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.tag-input-container input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 14px;
  width: 100px;
  margin-right: 4px;
}

.tag-input-container .tag-add-icon,
.tag-input-container .tag-cancel-icon {
  cursor: pointer;
  color: #28a745; /* Green for add icon */
  margin-right: 4px;
  transition: color 0.3s ease;
}

.tag-input-container .tag-add-icon:hover {
  color: #218838; /* Darker green on hover */
}

.tag-input-container .tag-cancel-icon {
  color: #dc3545; /* Red for cancel icon */
}

.tag-input-container .tag-cancel-icon:hover {
  color: #c82333; /* Darker red on hover */
}

.tag-input-container .tag-add-icon:focus,
.tag-input-container .tag-cancel-icon:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(40, 167, 69, 0.5); /* Green focus ring for add */
  /* Since both add and cancel icons share the same selector, consider separating if needed */
}

/* 7. Additional Adjustments */

/* Adjust the .tags-cell to prevent vertical stacking when there are multiple tags */
.tags-cell .tag-badge {
  /* Ensure badges don't force vertical stacking */
  margin-bottom: 4px;
}

/* Optional: Increase Add Tag Button Size if Needed */
/* Uncomment if you decide to increase the button size */
/*
.add-tag-button {
  width: 32px;
  height: 32px;
}

.add-tag-button .icon {
  font-size: 18px;
}
*/

/* Ensure that the tag button does not cause overflow */
.tags-cell {
  overflow: hidden;
}