.share-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .share-popup {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    position: relative;

  }
  
  .share-popup-header {
    background-color: #2196F3;
    color: white;
    padding: 15px 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .share-popup-header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
  }
  
  .share-popup-close {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
  
  .share-popup-content {
    padding: 20px;
    max-height: 300px;
    overflow-y: auto;
    overflow: visible;
  }
  
  .share-search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .share-user-list {
    display: flex;
    flex-direction: column;
  }
  
  .share-user-item {
    display: flex;
    align-items: center;
    padding: 8px 0;
    cursor: pointer;
  }
  
  .share-user-item input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .share-no-results {
    text-align: center;
    color: #666;
    padding: 20px 0;
  }
  
  .share-popup-footer {
    padding: 15px 20px;
    background-color: #f5f5f5;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    justify-content: flex-end;
  }
  
  .share-popup-button {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
  }
  
  .share-clear {
    background-color: #f44336;
    color: white;
    margin-right: 10px;
  }
  
  .share-submit {
    background-color: #4CAF50;
    color: white;
  }
  
  .share-submit:disabled {
    background-color: #9E9E9E;
    cursor: not-allowed;
  }

  .share-search-container {
    position: relative;
  }
  
  .share-autocomplete-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-top: none;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000; /* Ensure this is higher than other elements */
    list-style-type: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .share-autocomplete-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.share-autocomplete-item input[type="checkbox"] {
    margin-right: 10px;
}

.share-autocomplete-item:hover {
  background-color: #f0f0f0;
}
  .share-selected-users {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
  
  .share-selected-user {
    background-color: #e0e0e0;
    padding: 5px 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;
  }
  
  .share-remove-user {
    background: none;
    border: none;
    color: #666;
    margin-left: 5px;
    cursor: pointer;
  }
  
  .share-loading {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
  }