/* src/components/documentPage/ChunksModal.css */

/* Modal Overlay */
.chunks-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Darker semi-transparent background for better masking */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other elements */
  }
  
  /* Modal Content */
  .chunks-modal {
    background-color: #ffffff;
    padding: 20px 30px;
    border-radius: 10px;
    width: 60vw; /* 60% of the viewport width */
    height: 50vh; /* 50% of the viewport height */
    max-width: 800px; /* Optional: Maximum width for larger screens */
    max-height: 600px; /* Optional: Maximum height for larger screens */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    position: relative;
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden; /* Hide overflow by default */
  }
  
  /* Modal Header */
  .chunks-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Modal Title */
  .chunks-modal-header h2 {
    margin: 0;
    font-size: 24px;
    color: var(--text-color);
  }
  
  /* Close Button */
  .chunks-modal-close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--text-color);
    transition: color var(--transition-speed) ease;
  }
  
  .chunks-modal-close-button:hover {
    color: var(--primary-color);
  }
  
  /* Modal Body */
  .chunks-modal-body {
    flex: 1;
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
  }

  .chunks-modal-body::-webkit-scrollbar {
    width: 10px;
  }

  .chunks-modal-body::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
  }

  .chunks-modal-body::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;

  }
  
  /* Chunks Table within Modal */
  .chunks-modal-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
  }
  
  .chunks-modal-table th,
  .chunks-modal-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
    font-size: 14px;
    color: var(--text-color);
  }
  
  /* Allow chunk_id to wrap */
  .chunk-id-cell {
    word-wrap: break-word;
    max-width: 200px; /* Optional: Limit the maximum width */
  }
  
  /* Status Indicators */
  .status-complete {
    color: #ffffff; /* Green color for indexed status */
    font-weight: 600;
  }
  
  .status-incomplete {
    color: #e74c3c; /* Red color for non-indexed status */
    font-weight: 600;
  }
  
  /* Chunks Table Header */
  .chunks-modal-table th {
    background-color: var(--secondary-color);
    font-weight: 600;
  }
  
  /* Chunks Table Row Hover */
  .chunks-modal-table tbody tr:hover {
    background-color: var(--hover-background);
  }
  
  /* Reindex Button */
  .reindex-button {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    background-color: var(--primary-color);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: background-color var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
  }
  
  .reindex-button:hover {
    background-color: #0673e0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .reindex-icon {
    margin-right: 6px;
    font-size: 16px;
  }
  
  /* Loader within Modal */
  .chunks-modal-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; /* Fill the available height */
  }
  
  .chunks-modal-loader span {
    margin-top: 10px;
    font-size: 16px;
    color: var(--text-color);
  }
  
  /* Error Message within Modal */
  .chunks-modal-error {
    color: #e74c3c;
    text-align: center;
    font-size: 16px;
  }
  
  /* Responsive Modal Adjustments */
  @media (max-width: 768px) {
    .chunks-modal {
      width: 80vw; /* Adjust width for medium screens */
      height: 60vh; /* Adjust height for medium screens */
      padding: 15px 20px;
    }
  
    .chunks-modal-header h2 {
      font-size: 20px;
    }
  
    .chunks-modal-table th,
    .chunks-modal-table td {
      padding: 10px 12px;
      font-size: 13px;
    }
  
    .chunks-modal-loader span {
      font-size: 14px;
    }
  
    .reindex-button {
      padding: 5px 10px;
      font-size: 12px;
    }
  
    .reindex-icon {
      font-size: 14px;
      margin-right: 4px;
    }
  }
  
  @media (max-width: 480px) {
    .chunks-modal {
      width: 90vw; /* Adjust width for small screens */
      height: 70vh; /* Adjust height for small screens */
      padding: 10px 15px;
    }
  
    .chunks-modal-header h2 {
      font-size: 18px;
    }
  
    .chunks-modal-table th,
    .chunks-modal-table td {
      padding: 8px 10px;
      font-size: 12px;
    }
  
    .chunks-modal-loader span {
      font-size: 12px;
    }
  
    .reindex-button {
      padding: 4px 8px;
      font-size: 12px;
    }
  
    .reindex-icon {
      font-size: 12px;
      margin-right: 3px;
    }
  }
  